import { extendTheme } from "@chakra-ui/react";

import "@fontsource/inter/400.css";
import "@fontsource/inter/700.css";


const theme = {
  breakpoints: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
  },
  colors: {
    primary: {
      400: "#ED1B2488",
      500: "#ED1B24FF",
      900: "#ED1B24FF"
    },
    background: {
      aliceblue: "#F1F5FB",
      burgundy: {
        800: "#840322E5",
      },
      lightgray: "#D2D3D5",
    },
    border: {
      gainsboro: "#DEDEDE",
    },
    text: {
      primary: "#11142D",
      secondary: "#5C5C5C",
    },
    white: "#FFFFFF",
  },
  fonts: {
    heading: 'Inter',
    body: 'Inter',
  },
};


export default extendTheme(theme);
