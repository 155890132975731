import * as React from "react";
import {
  Heading
} from "@chakra-ui/react";


export const Heading2: React.FC = ( props ) => {
  return (
    <Heading
      as="h2"
      margin="32px 0"
      fontSize="48px"
      fontWeight={ 400 }
      { ...props }
    />
  )
}
